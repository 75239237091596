// JBP Notification Framework Toast Widgets 

// ESPP //
document.getElementById("acn-toast");
function enrollEsppToastProd(){
acntoasts.show("9d959a50-1ff4-11ea-824a-bd8a5bfbd632"); 
}

document.getElementById("acn-toast");
function esppToastWithdrawProd(){
acntoasts.show("abf42680-1ff3-11ea-9cb2-97f754bc51ef"); 
}

document.getElementById("acn-toast");
function enrollEsppToastStage(){
acntoasts.show("210b6ac0-0a71-11ea-8b68-c726a21d5e48"); 
}

document.getElementById("acn-toast");
function esppToastWithdrawStage(){
acntoasts.show("5599d680-0a78-11ea-aca9-7118f7bc726b");
} 

// VEIP //
document.getElementById("acn-toast");
function enrollVeipToastProd(){
acntoasts.show("870aba90-1ff4-11ea-9cb2-97f754bc51ef"); 
}

document.getElementById("acn-toast");
function veipToastWithdrawProd(){
acntoasts.show("5fb40a10-1ff3-11ea-9cb2-97f754bc51ef"); 
}

document.getElementById("acn-toast");
function enrollVeipToastStage(){
acntoasts.show("3f5156f0-0a78-11ea-aca9-7118f7bc726b");
}

document.getElementById("acn-toast");
function veipToastWithdrawStage(){
acntoasts.show("5e6a75d0-0a78-11ea-aca9-7118f7bc726b");
}





